import {EMPTY_IMAGE} from '../page/tools/buy_charge/CONSTANTS';
import {TextComponent} from '../core/components/base/textField.component';
import {ColumnComponent} from '../core/components/base/column.component';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';
import Image from 'next/image';

export default function FourOhFour() {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <ColumnComponent className={'h-full w-full items-center justify-center'}>
      <Image
        fill={true}
        sizes={'100%'}
        alt={'emptyImage'}
        className="mb-2 mt-3 !h-36 !w-32 !relative"
        src={EMPTY_IMAGE}
      />
      <TextComponent
        align={'center'}
        className={'w-full mt-2'}
        size={'lg'}
        text={t('pageNotFound')}
        isFromTranslate={false}
      />
      <button className={'bg-black rounded-md text-white mt-6 px-4 py-2'} onClick={() => router.push('/')}>
        {t('homePage')}
      </button>
    </ColumnComponent>
  );
}
