export const MNT_ACTIVE_IMAGE = '/image/svg_ic_MTN_active.svg';
export const MNT_IMAGE = '/image/svg_ic_MTN.svg';
export const HAMRAH_ACTIVE_IMAGE = '/image/svg_ic_MCI_active.svg';
export const HAMRAH_IMAGE = '/image/svg_ic_MCI.svg';
export const RIGHTEL_ACTIVE_IMAGE = '/image/svg_ic_RTL_active.svg';
export const RIGHTEL_IMAGE = '/image/svg_ic_RTL.svg';
export const SCORE_IMAGE = '/image/svg_ic_score.svg';
export const EMPTY_IMAGE = '/image/svg_ic_empty.svg';

export const RIGHTEL_REGEX = '^92[0|1|2][0-9]{7}$';
export const MTN_REGEX = '^9[3|0|4][0-9]{8}$';
export const HAMRAH_REGEX = '^9[1|9][0-9]{8}$';
export const MOBILE_REGEX = '^9[0-9]{9}$';

export const HAMRAH_OPERATOR = 'MCI';
export const MTN_OPERATOR = 'MTN';
export const RIGHTEL_OPERATOR = 'RTL';

export const BUNDLE_ADVISER_URL = 'r/payment/bundleadviser/v1/';

export const BUY_CHARGE_INQUIRY_URL = 'r/payment/charge/v1/inquiry/';
export const BUY_CHARGE_INVOICE_URL = 'r/payment/transaction/v3/invoice/charge/';
export const PAYMENT_COUPON_URL = 'r/payment/transaction/v3/coupon/';
export const CHARGE_FROM_WALLET = 'r/payment/charge/v3/buy/';
export const INCREASE_WALLET_FOR_BUY_WALLET = 'r/payment/transaction/v3/invoice/wallet/';

export const BUY_CHARGE_CHECK_COUPON_URL = 'r/payment/transaction/v1/';
export const BUY_CHARGE_COUPON_URL_TEXT = '/coupon/';
